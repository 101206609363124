
import React, { useEffect, useState } from 'react';
import './CpcbReport.css';
import * as XLSX from 'xlsx';
import { DatePicker, Select } from "antd";
import moment from 'moment';

// Base function to fetch data from the API

const CpcbReport = () => {

  const [siteIds, setSiteIds] = useState([]);
  const [siteNames, setSiteNames] = useState([]);
  const [selectedSiteIds, setSelectedSiteIds] = useState([]);
  const [smsloading, setsmsLoading] = useState(false); 
  const [nosmsloading, setnosmsLoading] = useState(false); 
  const [offlineloading, setofflineLoading] = useState(false);
  const [dates, setDates] = useState(null);
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const fetchData = async (url) => {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer bdRqZw97UrvVWe1gfebeGlaWzVF',
        'Content-Type': 'application/json',
      },
    });
  
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  
    const data = await response.json();
  
    if (!Array.isArray(data)) {
      throw new Error("Expected array but got something else");
    }
  
    return data;
  };
  
  // General function to create Excel workbook and download
  // Function to create Excel workbook and download
  const createExcelFile = (worksheet, reportType) => {
    const workbook = XLSX.utils.book_new();
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, `${reportType} Report`);
  
    // Set the styles for the header
    const headerCellStyle = {
      fill: {
        fgColor: { rgb: "FF0000" }, // Red background
      },
      alignment: {
        horizontal: "center",
        vertical: "center",
      },
      font: {
        bold: true,
        color: { rgb: "FFFFFF" }, // White text color
      },
    };
  
    // Apply styles to the header row (second row in the worksheet)
    const headerRow = 1; // Index for the header row (0-based)
  
    const headerColumns = worksheet["!ref"].split(":")[1]; // Get the last cell address
    const lastColumnIndex = XLSX.utils.decode_cell(headerColumns).c; // Get the last column index
  
    // Apply styles to the header cells
    for (let col = 0; col <= lastColumnIndex; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: headerRow, c: col }); // Cell address
      if (!worksheet[cellAddress]) {
        worksheet[cellAddress] = {}; // Create the cell if it doesn't exist
      }
      worksheet[cellAddress].s = headerCellStyle; // Apply the styles
    }
    
    // Center-align all cells in the worksheet
    const range = XLSX.utils.decode_range(worksheet["!ref"]); // Get the worksheet range
    for (let row = range.s.r; row <= range.e.r; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
        if (!worksheet[cellAddress]) {
          worksheet[cellAddress] = {}; // Create the cell if it doesn't exist
        }
        // Ensure alignment is applied correctly
        worksheet[cellAddress].s = {
          ...(worksheet[cellAddress].s || {}), // Keep existing styles
          alignment: {
            horizontal: "center", // Center align text
            vertical: "center",
          },
        };
      }
    }
  
    // Set the row height for the header
    if (!worksheet['!rows']) {
      worksheet['!rows'] = []; // Initialize rows if not defined
    }
    worksheet['!rows'][headerRow] = { hpt: 30 }; // Set height to 30 points (adjust as needed)
  
    // Set column widths for headers
    worksheet['!cols'] = [
      { wpx: 60 }, // Sr No
      { wpx: 60 }, // Category
      { wpx: 60 }, // Industry Code
      { wpx: 80 }, // Industry Name
      { wpx: 150 }, // Full Address
      { wpx: 60 }, // City
      { wpx: 60 }, // State
      { wpx: 80 }, // Station Name
      { wpx: 80 }, // Parameter
      { wpx: 80 }, // Parameter Limit
      { wpx: 120 }, // No of Exceedance
      { wpx: 100 }, // Total no of SMS in Industry
      { wpx: 80 }, // In Ganga Basin (Yes/No)
      { wpx: 80 }, // Name of the TPDS
    ];
  
    // Write the file
    XLSX.writeFile(workbook, `${reportType}-Report.xlsx`);
  };
  
  // Function for SMS report-specific formatting
  const formatSmsDataForExcel = (data) => {
    const formattedData = [];
    const headers = [
      "Sr No", "Category", "Industry Code", "Industry Name", "Full Address", "City",
      "State", "Station Name", "Parameters", "Parameter limit for triggering alerts", "No of Exceedance(based on 15 min Avg Data)(Max : 2976 for 31days & 2880 for 30 days in a month)",
      "Total no of SMS  in Industry", "In Ganga Basin (Yes\No)", "Name of the TPDS"
    ];
  
    const startToEnd = data[0]?.["StartToEnd"] || ""; 
    const dateInfo = startToEnd || '';
    const additionalHeader = [`SMS Report :- List of Industries with SMS alerts between ${dateInfo}`];
  
    // Iterate through each site and its stations
    data.forEach((site, siteIndex) => {
      let showSiteInfo = true; // Flag to control duplicate site info display
            
      site.stations.forEach((station, stationIndex) => {
        let showStationInfo = true; // Flag to control duplicate station info display
            
        station.parameters?.forEach((param, paramIndex) => {
          formattedData.push({
            "Sr No": showSiteInfo ? site["Sr No"] : "",  // Show "Sr No" only for the first parameter in the site
            "Category": showSiteInfo ? site.category : "",
            "Industry Code": showSiteInfo ? site.CPCB_Industry_Code : "",
            "Industry Name": showSiteInfo ? site.name : "",
            "Full Address": showSiteInfo ? site.address : "",
            "City": showSiteInfo ? site.city : "",
            "State": showSiteInfo ? site.state : "",
            "Station Name": showStationInfo ? station.station : "", // Show "Station Name" only for the first parameter in the station
            "Parameters": param.parameter || "",
            "Parameter limit for triggering alerts": `Min: ${param.min}, Max: ${param.max}` || "",
            "No of Exceedance(based on 15 min Avg Data)(Max : 2976 for 31days & 2880 for 30 days in a month)": param.Count || 0,
            "Total no of SMS  in Industry": showStationInfo ? site.SMS_Count : "",
            "In Ganga Basin (Yes\No)": showSiteInfo ? site["In Ganga Basin "] : "",  // Show "In Ganga Basin" only for the first parameter in the site
            "Name of the TPDS": showSiteInfo ? site["Name of the TPDS"] : "",
          });

          showSiteInfo = false; // After first iteration, set flag to false to avoid duplication
          showStationInfo = false; // After first parameter in the station, set to false to avoid duplication
        });
      });
    });

    const dataWithHeaders = [additionalHeader, headers, ...formattedData.map(Object.values)];
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeaders);
    return worksheet;
  };

  // Function for Offline report-specific formatting
  const formatOfflineDataForExcel = (data, endDate) => {

    const startToEnd = data[0]?.["StartToEnd"] || ""; 
    const dateInfo = startToEnd || '';

    const formattedData = [];
    const headers = [
      "Sr No", "Category", "Industry Code", "Industry Name", "Full Address", "City",
      "State", "Station Name", "Parameters", "Parameter limit for triggering alerts", `No of Offline Days between ${dateInfo}`,
      "Date and time of the last data point", `Count of number of days between the date of last data point and report cutoff date (${endDate})`,
      "In Ganga Basin (Yes/No)", "Name of the TPDS"
    ];

    const additionalHeader = [`Offline Report :- List of Industries that generated Offline reports between ${dateInfo}`];

    data.forEach(site => {
      site.stations.forEach(station => {
        station.parameters?.forEach((param, index) => {
          const isFirstRow = index === 0;

          formattedData.push({
            "Sr No": isFirstRow ? site["Sr.No"] : "",
            "Category": isFirstRow ? site.category : "",
            "Industry Code": isFirstRow ? site.CPCB_Industry_Code : "",
            "Industry Name": isFirstRow ? site.name : "",
            "Full Address": isFirstRow ? site.address : "",
            "City": isFirstRow ? site.city : "",
            "State": isFirstRow ? site.state : "",
            "Station Name": isFirstRow ? station.station : "",
            "Parameters": param.parameter,
            "Parameter limit for triggering alerts": `Min: ${param.min}, Max: ${param.max}`,
            [`No of Offline Days between ${dateInfo}`]:  isFirstRow ? site["Offlinedays"] : '',
            "Date and time of the last data point": param["last-date"],
            [`Count of number of days between the date of last data point and report cutoff date (${endDate})`]: param["days-difference"],
            "In Ganga Basin (Yes/No)": isFirstRow ? site["In Ganga Basin"] : '',
            "Name of the TPDS": isFirstRow ? site["Name of the TPDS"] : '',
          });
        });
      });
    });


    const dataWithHeaders = [additionalHeader, headers, ...formattedData.map(Object.values)];
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeaders);
    return worksheet;
  };

  // Function for No SMS report-specific formatting
  const formatNoSmsDataForExcel = (data) => {
    const formattedData = [];
    const headers = [
      "Sr No", "Category", "Industry Code", "Industry Name", "Full Address", "City",
      "State", "Station Name", "Parameters", "Parameter limit for triggering alerts",
      "In Ganga Basin (Yes/No)", "Name of the TPDS"
    ];

    const startToEnd = data[0]?.["StartToEnd"] || ""; 
    const dateInfo = startToEnd || '';
    const additionalHeader = [`No SMS Report :- List of Industries with no SMS alerts between ${dateInfo}`];

    // Iterate through each site and its stations
    data.forEach((site, siteIndex) => {
      let showSiteInfo = true; // Flag to control duplicate site info display

      site.stations.forEach((station, stationIndex) => {
        let showStationInfo = true; // Flag to control duplicate station info display

        station.parameters?.forEach((param, paramIndex) => {
          formattedData.push({
            "Sr No": showSiteInfo ? site["Sr No"] : "",  // Show "Sr No" only for the first parameter in the site
            "Category": showSiteInfo ? site.category : "",
            "Industry Code": showSiteInfo ? site.CPCB_Industry_Code: "",
            "Industry Name": showSiteInfo ? site.name : "",
            "Full Address": showSiteInfo ? site.address : "",
            "City": showSiteInfo ? site.city : "",
            "State": showSiteInfo ? site.state : "",
            "Station Name": showStationInfo ? station.station : "", // Show "Station Name" only for the first parameter in the station
            "Parameters": param.parameter || "",
            "Parameter limit for triggering alerts": `Min: ${param.min}, Max: ${param.max}` || "",
            "In Ganga Basin (Yes/No)": showSiteInfo ? site["In Ganga Basin "] : "",  // Show "In Ganga Basin" only for the first parameter in the site
            "Name of the TPDS": showSiteInfo ? site["Name of the TPDS"] : "",
          });
          showSiteInfo = false; // After first iteration, set flag to false to avoid duplication
          showStationInfo = false; // After first parameter in the station, set to false to avoid duplication
        });
      });
    });

    const dataWithHeaders = [additionalHeader, headers, ...formattedData.map(Object.values)];
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeaders);
    return worksheet;
  };
  
  
  // Handler to download SMS report
  const downloadSmsReportData = async () => {
    if (!dates || selectedSiteIds.length === 0) {
      alert("Please select both date range and site IDs.");
      return;
    }
    
    const startDate = dates[0].format('YYYY-MM-DD');
    const endDate = dates[1].format('YYYY-MM-DD');
    
    // Create the site_id query string with multiple site_id parameters
    const siteIdQuery = selectedSiteIds.map(siteId => `site_id=${siteId}`).join('&');  // Join each site_id with '&'
    
    // Ensure that the URL is correctly formed with each site_id as a separate parameter
    const url = `http://envicrafts.com:8080/sites/SMSCpcbReport/?from=${startDate}&to=${endDate}&${siteIdQuery}`;
    setsmsLoading(true);
    try {
      const data = await fetchData(url);
      console.log("Fetched data:", data); // Check the fetched data in the console
      const worksheet = formatSmsDataForExcel(data);
      createExcelFile(worksheet, "SMS");
    } catch (error) {
      console.error("Error fetching or downloading SMS report:", error);
      alert("sorry no data in this date")
    }finally {
       setsmsLoading(false); // Ensure loading is reset
       }
  };
  
  
  // Handler to download No SMS report
  const downloadNoSmsReportData = async () => {
    // const url = "http://envicrafts.com:8080/sites/NoSmsCpcbReport/";
      
    if (!dates || selectedSiteIds.length === 0) {
      alert("Please select both date range and site IDs.");
      return;
    }
      
    const startDate = dates[0].format('YYYY-MM-DD');
    const endDate = dates[1].format('YYYY-MM-DD');
      
    // Create the site_id query string with multiple site_id parameters
    const siteIdQuery = selectedSiteIds.map(siteId => `site_id=${siteId}`).join('&');  // Join each site_id with '&'
    
    // Ensure that the URL is correctly formed with each site_id as a separate parameter
    const url = `http://envicrafts.com:8080/sites/NoSmsCpcbReport/?from=${startDate}&to=${endDate}&${siteIdQuery}`;
    setnosmsLoading(true)
    try {
      const data = await fetchData(url);
      console.log(" No sms Fetched data:", data);
      const worksheet = formatNoSmsDataForExcel(data);
      createExcelFile(worksheet, 'No SMS');
    } catch (error) {
      console.error('Error fetching or downloading No SMS report:', error);
      alert("sorry no data in this date")
    }finally {
            setnosmsLoading(false); // Ensure loading is reset
        }
  };


  // Handler to download Offline report
  const downloadOfflineReportData = async () => {
    // const url = "http://envicrafts.com:8080/sites/SitesOfflineReport/";

    if (!dates || selectedSiteIds.length === 0) {
      alert("Please select both date range and site IDs.");
      return;
    }

    const startDate = dates[0].format('YYYY-MM-DD');
    const endDate = dates[1].format('YYYY-MM-DD');

    // Create the site_id query string with multiple site_id parameters
    const siteIdQuery = selectedSiteIds.map(siteId => `site_id=${siteId}`).join('&');  // Join each site_id with '&'

    // Ensure that the URL is correctly formed with each site_id as a separate parameter
    const url = `http://envicrafts.com:8080/sites/SitesOfflineReport/?from=${startDate}&to=${endDate}&${siteIdQuery}`;
     setofflineLoading(true);
    try {
      const data = await fetchData(url);
      const worksheet = formatOfflineDataForExcel(data, endDate);
      createExcelFile(worksheet, 'Offline');
    } catch (error) {
      console.error('Error fetching or downloading Offline report:', error);
      alert("sorry no data in this date")
    }finally {
      setofflineLoading(false); // Ensure loading is reset
    }
  };


  useEffect(() => {
    fetch("http://envicrafts.com:8080/sites/", {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      // Verify that data is an array before proceeding
      if (Array.isArray(data)) {
        // Filter sites where CPCB Status is true
        const filteredData = data.filter((item) => item["CPCB Status"]);
        const ids = filteredData.map((item) => item.site_id);
        const names = filteredData.map((item) => item.name);
        setSiteIds(ids);
        setSiteNames(names);
      } else {
        console.error("Unexpected data format:", data);
      }
    })
      .catch(error => console.error('Error fetching Site IDs:', error));
  }, []);

  // const handleSelectionChange = (value) => {
  //   setSelectedSiteIds(value);  // Directly set the selected values
  // };

  const handleSelectionChange = (value) => {
    if (value.includes("all")) {
      if (selectedSiteIds.length === siteIds.length) {
        setSelectedSiteIds([]); // Unselect all if all are selected
      } else {
        setSelectedSiteIds(siteIds); // Select all
      }
    } else {
      setSelectedSiteIds(value);
    }
  };

  const handleDateChange = (dates) => {
    setDates(dates);
  };

  const disableFutureMonthArrow = (current) => {
    const today = moment(); // Use moment() to get today's date
    return current && current.month() > today.month(); // Disable months ahead of the current month
  };


  return (
    <div className="cpcb-report-container">
    {/* <h2>CPCB Reports</h2> */}
    <div className="report-section">
      <div className="cpcb-report-options">
        <div className="cpcb-report-input-group">
          <label className="cpcb-report-label" htmlFor="siteIdSelect">Select Site Names:</label>
          <Select
            id="siteIdSelect"
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Select Site Names"
            value={selectedSiteIds}
            onChange={handleSelectionChange}
          >
            <Option value="all">Select All</Option>
            {siteIds.map((siteId, index) => (
              <Option key={siteId} value={siteId}>
                {siteNames[index]}
              </Option>
            ))}
          </Select>
        </div>

        <div className="cpcb-report-input-group">
          <label className="cpcb-report-label">Select Start and End Date:</label>
          <RangePicker
            className="generatereport__filter__dropdown"
            size="large"
            onChange={handleDateChange}
            disabledDate={(current) => current && current.isAfter(moment().endOf('day'))} // Use moment() to disable future dates
            disabledMonth={disableFutureMonthArrow} 
          />
        </div>
      </div>


      <div className='cpcb-reports-buttons'>
      <div className="report">
        <h3>Offline Report</h3>
        <p>View and download the latest offline reports.</p>
        <button className="download-btn" onClick={downloadOfflineReportData}>
          {offlineloading ? "Loading..." : "Download Offline Report"}
        </button>
      </div>
      <div className="report">
        <h3>SMS Report</h3>
        <p>View and download the latest SMS reports.</p>
        <button className="download-btn" onClick={downloadSmsReportData}>
          {smsloading ? "Loading..." : "Download SMS Report"}
        </button>
      </div>
      <div className="report">
        <h3>No SMS Report</h3>
        <p>View and download the latest No SMS reports.</p>
        <button className="download-btn" onClick={downloadNoSmsReportData}>
          {nosmsloading ? "Loading..." : "Download No SMS Report"}
        </button>
      </div>
      </div>
    </div>
  </div>
  );
};

export default CpcbReport;




// import React, { useEffect, useState } from "react";
// import "./CpcbReport.css";
// import * as XLSX from "xlsx";
// import { DatePicker, Select } from "antd";

// // Base function to fetch data from the API

// const CpcbReport = () => {
//   const [smsloading, setsmsLoading] = useState(false); // Common loading state
//   const [nosmsloading, setnosmsLoading] = useState(false); // Common loading state
//   const [offlineloading, setofflineLoading] = useState(false); // Common loading state

//   const fetchData = async (url) => {
//     const response = await fetch(url, {
//       method: "GET",
//       headers: {
//         Authorization: "Bearer bdRqZw97UrvVWe1gfebeGlaWzVF",
//         "Content-Type": "application/json",
//       },
//     });

//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }

//     const data = await response.json();

//     if (!Array.isArray(data)) {
//       throw new Error("Expected array but got something else");
//     }

//     return data;
//   };

//   // General function to create Excel workbook and download
//   // Function to create Excel workbook and download
//   const createExcelFile = (worksheet, reportType) => {
//     const workbook = XLSX.utils.book_new();

//     // Add the worksheet to the workbook
//     XLSX.utils.book_append_sheet(workbook, worksheet, `${reportType} Report`);

//     // Set the styles for the header
//     const headerCellStyle = {
//       fill: {
//         fgColor: { rgb: "FF0000" }, // Red background
//       },
//       alignment: {
//         horizontal: "center",
//         vertical: "center",
//       },
//       font: {
//         bold: true,
//         color: { rgb: "FFFFFF" }, // White text color
//       },
//     };

//     // Apply styles to the header row (second row in the worksheet)
//     const headerRow = 1; // Index for the header row (0-based)

//     const headerColumns = worksheet["!ref"].split(":")[1]; // Get the last cell address
//     const lastColumnIndex = XLSX.utils.decode_cell(headerColumns).c; // Get the last column index

//     // Apply styles to the header cells
//     for (let col = 0; col <= lastColumnIndex; col++) {
//       const cellAddress = XLSX.utils.encode_cell({ r: headerRow, c: col }); // Cell address
//       if (!worksheet[cellAddress]) {
//         worksheet[cellAddress] = {}; // Create the cell if it doesn't exist
//       }
//       worksheet[cellAddress].s = headerCellStyle; // Apply the styles
//     }

//     // Center-align all cells in the worksheet
//     const range = XLSX.utils.decode_range(worksheet["!ref"]); // Get the worksheet range
//     for (let row = range.s.r; row <= range.e.r; row++) {
//       for (let col = range.s.c; col <= range.e.c; col++) {
//         const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
//         if (!worksheet[cellAddress]) {
//           worksheet[cellAddress] = {}; // Create the cell if it doesn't exist
//         }
//         // Ensure alignment is applied correctly
//         worksheet[cellAddress].s = {
//           ...(worksheet[cellAddress].s || {}), // Keep existing styles
//           alignment: {
//             horizontal: "center", // Center align text
//             vertical: "center",
//           },
//         };
//       }
//     }

//     // Set the row height for the header
//     if (!worksheet["!rows"]) {
//       worksheet["!rows"] = []; // Initialize rows if not defined
//     }
//     worksheet["!rows"][headerRow] = { hpt: 30 }; // Set height to 30 points (adjust as needed)

//     // Set column widths for headers
//     worksheet["!cols"] = [
//       { wpx: 60 }, // Sr No
//       { wpx: 60 }, // Category
//       { wpx: 60 }, // Industry Code
//       { wpx: 80 }, // Industry Name
//       { wpx: 150 }, // Full Address
//       { wpx: 60 }, // City
//       { wpx: 60 }, // State
//       { wpx: 80 }, // Station Name
//       { wpx: 80 }, // Parameter
//       { wpx: 80 }, // Parameter Limit
//       { wpx: 120 }, // No of Exceedance
//       { wpx: 100 }, // Total no of SMS in Industry
//       { wpx: 80 }, // In Ganga Basin (Yes/No)
//       { wpx: 80 }, // Name of the TPDS
//     ];
//     // Write the file
//     XLSX.writeFile(workbook, `${reportType}-Report.xlsx`);
//   };

//   // Function for SMS report-specific formatting
//   const formatSmsDataForExcel = (data) => {
//     const formattedData = [];
//     const headers = [
//       "Sr No",
//       "Category",
//       "Industry Code",
//       "Industry Name",
//       "Full Address",
//       "City",
//       "State",
//       "Station Name",
//       "Parameter",
//       "Parameter Limit",
//       "No of Exceedance(based on 15 min Avg Data)(Max : 2976 for 31days & 2880 for 30 days in a month)",
//       "Total no of SMS in Industry",
//       "In Ganga Basin (Yes/No)",
//       "Name of the TPDS",
//     ];
  
//     // Set additional header with date info, if available
//     const startToEnd = data[0]?.["StartToEnd"] || "";
//     const additionalHeader = [
//       [`SMS Report: List of Industries with SMS alerts between ${startToEnd}`],
//     ];

//     // Iterate through each site and its stations
//     data.forEach((site, siteIndex) => {
//       let showSiteInfo = true; // Flag to control duplicate site info display

//       site.stations?.forEach((station, stationIndex) => {
//         let showStationInfo = true; // Flag to control duplicate station info display

//         station.parameters?.forEach((param, paramIndex) => {
//           formattedData.push({
//             "Sr No": showSiteInfo ? siteIndex + 1 : "", // Display only once per site
//             Category: showSiteInfo ? site.category : "",
//             "Industry Code": showSiteInfo ? site.site_id : "",
//             "Industry Name": showSiteInfo ? site.name : "",
//             "Full Address": showSiteInfo ? site.address : "",
//             City: showSiteInfo ? site.city : "",
//             State: showSiteInfo ? site.state : "",
//             "Station Name": showStationInfo ? station.station : "",
//             Parameter: param.parameter || "",
//             "Parameter Limit": param.min && param.max ? `Min: ${param.min}, Max: ${param.max}` : "",
//             "No of Exceedance(based on 15 min Avg Data)(Max : 2976 for 31days & 2880 for 30 days in a month)":
//               param.Count || 0,
//             "Total no of SMS in Industry": showStationInfo ? site.SMS_Count : "",
//             "In Ganga Basin (Yes/No)": showSiteInfo ? site["In Ganga Basin "] : "",
//             "Name of the TPDS": showSiteInfo ? site["Name of the TPDS"] : "",
//           });


//           showSiteInfo = false; // Avoid duplication for subsequent parameters
//           showStationInfo = false;
//         });
//       });
//     });
  
//     const dataWithHeaders = [
//       additionalHeader,
//       headers,
//       ...formattedData.map((item) => headers.map((header) => item[header] || "")),
//     ];
//     const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeaders);
//     return worksheet;
//   };
  

//   // Function for Offline report-specific formatting
//   const formatOfflineDataForExcel = (data) => {
//     const formattedData = [];
//     const headers = [
//       "Sr No",
//       "Category",
//       "Industry Code",
//       "Industry Name",
//       "Full Address",
//       "City",
//       "State",
//       "Station Name",
//       "Parameter",
//       "Parameter Limit",
//       "No of Offline Reports",
//       "Last Value",
//       "Days Difference",
//       "In Ganga Basin (Yes/No)",
//       "Name of the TPDS",
//     ];

//     const startToEnd = data[0]?.["StartToEnd"] || "";
//     const dateInfo = startToEnd || "";
//     const additionalHeader = [
//       `Offline Report :- List of Industries that generated Offline reports between ${dateInfo}`,
//     ];

//     data.forEach((site) => {
//       site.stations.forEach((station) => {
//         station.parameters?.forEach((param, index) => {
//           const isFirstRow = index === 0;

//           formattedData.push({
//             "Sr No": isFirstRow ? site["Sr.No"] : "",
//             Category: isFirstRow ? site.category : "",
//             "Industry Code": isFirstRow ? site.site_id : "",
//             "Industry Name": isFirstRow ? site.name : "",
//             "Full Address": isFirstRow ? site.address : "",
//             City: isFirstRow ? site.city : "",
//             State: isFirstRow ? site.state : "",
//             "Station Name": isFirstRow ? station.station : "",
//             Parameter: param.parameter,
//             "Parameter Limit": `Min: ${param.min}, Max: ${param.max}`,
//             "No of Offline Reports": param.offlineReportCount || 0,
//             "Last Value": param["last-value"],
//             "Days Difference": param["days-difference"],
//             "In Ganga Basin (Yes/No)": isFirstRow ? site["In Ganga Basin"] : "",
//             "Name of the TPDS": isFirstRow ? site["Name of the TPDS"] : "",
//           });
//         });
//       });
//     });

//     const dataWithHeaders = [
//       additionalHeader,
//       headers,
//       ...formattedData.map(Object.values),
//     ];
//     const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeaders);
//     return worksheet;
//   };

//   // Function for No SMS report-specific formatting
//   const formatNoSmsDataForExcel = (data) => {
//     const formattedData = [];
//     const headers = [
//       "Sr No",
//       "Category",
//       "Industry Code",
//       "Industry Name",
//       "Full Address",
//       "City",
//       "State",
//       "Station Name",
//       "Parameter",
//       "Parameter Limit",
//       "In Ganga Basin (Yes/No)",
//       "Name of the TPDS",
//     ];

//     const startToEnd = data[0]?.["StartToEnd"] || "";
//     const dateInfo = startToEnd || "";
//     const additionalHeader = [
//       `No SMS Report :- List of Industries with no SMS alerts between ${dateInfo}`,
//     ];

//     // Iterate through each site and its stations
//     data.forEach((site, siteIndex) => {
//       let showSiteInfo = true; // Flag to control duplicate site info display

//       site.stations.forEach((station, stationIndex) => {
//         let showStationInfo = true; // Flag to control duplicate station info display

//         station.parameters?.forEach((param, paramIndex) => {
//           formattedData.push({
//             "Sr No": showSiteInfo ? site["Sr No"] : "", // Show "Sr No" only for the first parameter in the site
//             Category: showSiteInfo ? site.category : "",
//             "Industry Code": showSiteInfo ? site.site_id : "",
//             "Industry Name": showSiteInfo ? site.name : "",
//             "Full Address": showSiteInfo ? site.address : "",
//             City: showSiteInfo ? site.city : "",
//             State: showSiteInfo ? site.state : "",
//             "Station Name": showStationInfo ? station.station : "", // Show "Station Name" only for the first parameter in the station
//             Parameter: param.parameter || "",
//             "Parameter Limit": `Min: ${param.min}, Max: ${param.max}` || "",
//             "In Ganga Basin (Yes/No)": showSiteInfo
//               ? site["In Ganga Basin "]
//               : "", // Show "In Ganga Basin" only for the first parameter in the site
//             "Name of the TPDS": showSiteInfo ? site["Name of the TPDS"] : "",
//           });

//           showSiteInfo = false; // After first iteration, set flag to false to avoid duplication
//           showStationInfo = false; // After first parameter in the station, set to false to avoid duplication
//         });
//       });
//     });

//     const dataWithHeaders = [
//       additionalHeader,
//       headers,
//       ...formattedData.map(Object.values),
//     ];
//     const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeaders);
//     return worksheet;
//   };

//   // Handler to download SMS report
//   const downloadSmsReportData = async () => {
//     try {
//       // Start loading
//       setsmsLoading(true);
  
//       // Fetch SMS report data using the fetchData function
//       const url = `http://envicrafts.com:8080/sites/SMSCpcbReport/?from=${startDate}&to=${endDate}&${selectedSiteIds
//         .map((id) => `site_ids=${id}`)
//         .join("&")}`;
  
//       const data = await fetchData(url);
  
//       // Format the fetched data for Excel
//       const worksheet = formatSmsDataForExcel(data);
  
//       // Create and download the Excel file
//       createExcelFile(worksheet, "SMS");
  
//       // Stop loading
//       setsmsLoading(false);
//     } catch (error) {
//       console.error("Error downloading SMS report:", error);
//       setsmsLoading(false);
//     }
//   };
  
  
  
//   // Handler to download No SMS report
//   const downloadNoSmsReportData = async () => {
//     const url = `http://envicrafts.com:8080/sites/NoSmsCpcbReport/?from=${startDate}&to=${endDate}&${selectedSiteIds
//       .map((id) => `site_id=${id}`)
//       .join("&")}`;
//     setnosmsLoading(true);
//     try {
//       const data = await fetchData(url);
  
//       // Filter data by startDate and endDate if necessary
//       const filteredData = data.filter(item => {
//         const reportDate = new Date(item.date); // Assuming 'date' is the property for report date
//         return reportDate >= new Date(startDate) && reportDate <= new Date(endDate);
//       });
  
//       const worksheet = formatNoSmsDataForExcel(filteredData);
//       createExcelFile(worksheet, "No SMS");
//     } catch (error) {
//       console.error("Error fetching or downloading No SMS report:", error);
//     } finally {
//       setnosmsLoading(false); // Ensure loading is reset
//     }
//   };
  
//   // Handler to download Offline report
//   const downloadOfflineReportData = async () => {
//     const url = `http://envicrafts.com:8080/sites/SitesOfflineReport/?from=${startDate}&to=${endDate}&${selectedSiteIds
//       .map((id) => `site_id=${id}`)
//       .join("&")}`;
//     setofflineLoading(true);
//     try {
//       const data = await fetchData(url);
  
//       // Filter data by startDate and endDate if necessary
//       const filteredData = data.filter(item => {
//         const reportDate = new Date(item.date); // Assuming 'date' is the property for report date
//         return reportDate >= new Date(startDate) && reportDate <= new Date(endDate);
//       });
  
//       const worksheet = formatOfflineDataForExcel(filteredData);
//       createExcelFile(worksheet, "Offline");
//     } catch (error) {
//       console.error("Error fetching or downloading Offline report:", error);
//     } finally {
//       setofflineLoading(false); // Ensure loading is reset
//     }
//   };

//   const [siteIds, setSiteIds] = useState([]);
//   const [siteNames, setSiteNames] = useState([]);
//   const [selectedSiteIds, setSelectedSiteIds] = useState([]);
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const { RangePicker } = DatePicker;
//   const { Option } = Select;

//   // Fetch site data and filter based on CPCB Status
//   useEffect(() => {
//     fetch("http://envicrafts.com:8080/sites/", {
//       headers: {
//         Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
//       },
//     })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//         return response.json();
//       })
//       .then((data) => {
//         // Verify that data is an array before proceeding
//         if (Array.isArray(data)) {
//           // Filter sites where CPCB Status is true
//           const filteredData = data.filter((item) => item["CPCB Status"]);
//           const ids = filteredData.map((item) => item.site_id);
//           const names = filteredData.map((item) => item.name);
//           setSiteIds(ids);
//           setSiteNames(names);
//         } else {
//           console.error("Unexpected data format:", data);
//         }
//       })
//       .catch((error) => console.error("Error fetching Site IDs:", error));
//   }, []);
  

//   const handleSelectionChange = (value) => {
//     if (value.includes("all")) {
//       if (selectedSiteIds.length === siteIds.length) {
//         setSelectedSiteIds([]); // Unselect all if all are selected
//       } else {
//         setSelectedSiteIds(siteIds); // Select all
//       }
//     } else {
//       setSelectedSiteIds(value);
//     }
//   };

//   const handleDateChange = (dates) => {
//     if (dates) {
//       setStartDate(dates[0].format("YYYY-MM-DD"));
//       setEndDate(dates[1].format("YYYY-MM-DD"));
//     } else {
//       setStartDate(null);
//       setEndDate(null);
//     }
//   };

//   return (
//     <div className="cpcb-report-container">
//       <h2>CPCB Reports</h2>
//       <div className="report-section">
//         <label htmlFor="siteIdSelect">Select Site Names:</label>

//         <Select
//           id="siteIdSelect"
//           mode="multiple"
//           style={{ width: "100%" }}
//           placeholder="Select Site Names"
//           value={selectedSiteIds}
//           onChange={handleSelectionChange}
//         >
//           <Option value="all">Select All</Option>

//           {siteIds.map((siteId, index) => (
//             <Option key={siteId} value={siteId}>
//               {siteNames[index]}
//             </Option>
//           ))}
//         </Select>

//         <span>Select Start and End Date</span>
//         <RangePicker
//           className="generatereport__filter__dropdown"
//           size="large"
//           onChange={handleDateChange}
//         />

//         <div className="report">
//           <h3>Offline Report</h3>
//           <p>View and download the latest offline reports.</p>
//           <button className="download-btn" onClick={() => downloadOfflineReportData("Offline")} disabled={offlineloading}>
//             {offlineloading ? "Loading..." : "Download Offline Report"}
//           </button>
//         </div>

//         <div className="report">
//           <h3>SMS Report</h3>
//           <p>View and download the latest SMS reports.</p>
//           <button className="download-btn" onClick={() => downloadSmsReportData("SMS")} disabled={smsloading}>
//             {smsloading ? "Loading..." : "Download SMS Report"}
//           </button>
//         </div>

//         <div className="report">
//           <h3>No SMS Report</h3>
//           <p>View and download the latest No SMS reports.</p>
//           <button className="download-btn" onClick={downloadNoSmsReportData} disabled={nosmsloading}>
//             {nosmsloading ? "Loading..." : "Download No SMS Report"}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CpcbReport;